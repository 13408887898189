import React from "react";
import "./blogMultimedia.scss";
import Observer from "react-intersection-observer";
import { TweenMax as TM, Power2, Power3 } from "gsap/all";
import { ContextProviderComponent } from "../../contextProviderComponent";

import { Link } from "gatsby";

import { isMobile, isTablet, isDesktop } from "react-device-detect";

class BlogMultimedia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: this.props.slice.primary.description || "",
      image: this.props.slice.primary.image || null
    };
  }

  componentDidMount() {}

  handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(this.image, 0.8, {
        opacity: 1,
        ease: Power2.easeInOut,
        delay: 0.0
      });
      TM.to(this.description, 0.8, {
        opacity: 1,
        ease: Power2.easeInOut,
        delay: 0.0
      });
    }
  }

  render() {
    return (
      <section id="blogMultimedia" className="blogMultimedia">
        <Observer
          threshold="0.0"
          onChange={(inView, entry) =>
            this.handleContentVisibility(inView, entry)
          }
          className="wrapper"
        >
          <div className="blogMultimedia_cont">
            <img
              alt="smartwealth blog image"
              ref={ref => (this.image = ref)}
              src={this.state.image.url}
            />
            <span ref={ref => (this.description = ref)}>
              {this.state.description.text}
            </span>
          </div>
        </Observer>
      </section>
    );
  }
}
BlogMultimedia.contextType = ContextProviderComponent;
export default BlogMultimedia;
