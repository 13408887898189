import React from "react";
import $ from "jquery";
import "./whySmartwealthComponent.scss";
import Observer from "react-intersection-observer";

import {
  isBrowser,
  isMobile,
  isTablet,
  isDesktop,
  isMobileOnly
} from "react-device-detect";

import { gsap } from "gsap";
import { TweenMax as TM, Power0, Power2, Power3, Power4 } from "gsap/all";

import { ContextProviderComponent } from "../../contextProviderComponent";

class WhySmartwealthComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionNumber: this.props.slice.primary.section_number.text,
      sectionName: this.props.slice.primary.section_name.text,
      sectionHeading: this.props.slice.primary.section_heading.text,
      buttonText: this.props.slice.primary.button_text.text,
      buttonLink: this.props.slice.primary.button_link
        ? this.props.slice.primary.button_link.url
        : "",
      points: this.props.slice.items || []
    };
  }

  componentDidMount() {
    if (
      (isDesktop && !isTablet) ||
      (isTablet && window.innerWidth > window.innerHeight)
    ) {
      let newHeight = $(".whySmartwealthSection").height() - 30;
      $(".whySmartwealthSection").css("height", newHeight);
    }
  }

  handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(entry.target.firstChild, 0.8, {
        opacity: 1,
        ease: Power2.easeInOut,
        delay: 0.0
      });

      if (isDesktop && !isTablet) {
      }
    } else {
      if (isDesktop && !isTablet) {
      }
    }
  }

  render() {
    return (
      <section id="whySmartwealthSection" className="whySmartwealthSection">
        <Observer
          threshold="0.2"
          onChange={(inView, entry) =>
            this.handleContentVisibility(inView, entry)
          }
          className="wrapper"
        >
          <div className="whySmartwealthSection__cont">
            <div className="whySmartwealthSection__cont_left">
              <div className="whySmartwealthSection__cont_left__pageNumber">
                <span>{this.state.sectionNumber}</span>
              </div>
              <div className="whySmartwealthSection__cont_left__text">
                {(isMobileOnly ||
                  (isTablet && window.innerHeight > window.innerWidth)) && (
                  <div style={{ display: "flex" }}>
                    <span style={{ marginRight: "24px" }}>
                      {this.state.sectionNumber}
                    </span>
                    <span>{this.state.sectionName}</span>
                  </div>
                )}
                {((isDesktop && !isTablet) ||
                  (isTablet && window.innerHeight < window.innerWidth)) && (
                  <span>{this.state.sectionName}</span>
                )}
                <h2>{this.state.sectionHeading}</h2>
                <div
                  className="button button-hoverBlue"
                  onClick={() =>
                    this.state.buttonLink != ""
                      ? window.open(this.state.buttonLink, "_blank")
                      : this.context.callBackForCalendlyModalShow("flex")
                  }
                >
                  <p>{this.state.buttonText}</p>
                </div>
              </div>
            </div>

            <div className="whySmartwealthSection__cont_right">
              {this.state.points.map((pointItem, i) => {
                return (
                  <div
                    key={i}
                    className="whySmartwealthSection__cont_right__point"
                  >
                    <span>{i < 9 ? "0" + (i + 1) : i + 1}</span>
                    <p>
                      {pointItem.point_item_bold.text}{" "}
                      {pointItem.point_item_text.text}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </Observer>
      </section>
    );
  }
}
WhySmartwealthComponent.contextType = ContextProviderComponent;
export default WhySmartwealthComponent;
