import React from "react";
import { navigate } from "gatsby";
import linkResolver from "../../utils/linkResolver";
import { useEffect } from "react";
import { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    //margin: theme.spacing(1),
    //minWidth: 120,
  },
  select: {
    color: "white",
    fontFamily: "Avenir",
    fontSize: "12px",
    fontWeight: 800,
    letterSpacing: "0.04em",
    minWidth: "50px",
  },
  icon: {
    fill: "white",
  },
  input: {
    minHeight: "unset",
  },
  menuItem: {
    color: "#FFFFFF",
    fontFamily: "Avenir",
    fontSize: "12px",
    fontWeight: 800,
    letterSpacing: "0.04em",
  },
}));

const LanguageSwitcher = ({ activeDocMeta }) => {
  const classes = useStyles();

  const currentLang = activeDocMeta.lang;

  const currentLangOption = (
    <MenuItem value={currentLang} className={classes.menuItem}>
      {currentLang.slice(0, 2).toUpperCase()}
    </MenuItem>
  );

  const alternateLangOptions = activeDocMeta.alternateLanguages.map(
    (altLang, index) => (
      <MenuItem
        className={classes.menuItem}
        value={altLang}
        key={`alt-lang-${index}`}
      >
        {altLang.lang.slice(0, 2).toUpperCase()}
      </MenuItem>
    )
  );

  const handleLangChange = (event) => {
    const path = window.location.pathname.split("/");
    let type = "";
    let blogPath = "";

    let offset = 1;
    if (path[path.length - offset] != "") {
      if (
        path[path.length - offset] == "whysmartwealth" ||
        path[path.length - offset] == "warumsmartwealth"
      ) {
        type = "whysmartwealth";
      } else if (
        path[path.length - offset] == "aboutus" ||
        path[path.length - offset] == "uberuns"
      ) {
        type = "aboutus";
      } else if (
        path[path.length - offset] == "contactus" ||
        path[path.length - offset] == "kontaktiereuns"
      ) {
        type = "contactus";
      } else if (
        path[path.length - offset] == "privateclient" ||
        path[path.length - offset] == "privatkunden"
      ) {
        type = "privateclient";
      } else if (path[path.length - offset] == "b2b") {
        type = "b2b";
      } else if (
        path[path.length - offset] == "institutionalclients" ||
        path[path.length - offset] == "institutionellekunden"
      ) {
        type = "institutionalclients";
      } else if (path.includes("blog")) {
        type = "blogpost";
        blogPath = path[path.length - offset];
      } else if (
        path[path.length - offset] == "brochures" ||
        path[path.length - offset] == "brochures"
      ) {
        type = "brochures";
      } else if (
        path[path.length - offset] == "3iq" ||
        path[path.length - offset] == "3iq"
      ) {
        type = "3iq";
      }

      navigate(
        linkResolver({
          lang: event.target.value.lang,
          type: type,
          blogPath: blogPath,
        })
      );
    } else {
      navigate(linkResolver(event.target.value));
    }
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        className={classes.select}
        labelId="language-selector-label"
        id="language-selector"
        value={currentLang}
        onChange={handleLangChange}
        disableUnderline={true}
        inputProps={{
          classes: {
            icon: classes.icon,
            root: classes.input,
          },
        }}
      >
        {currentLangOption}
        {alternateLangOptions}
      </Select>
    </FormControl>
  );
};

export default LanguageSwitcher;
