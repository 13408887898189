import React, { useState, useEffect, useContext } from "react";
import "./onboardingModalComponent.scss";
import closeImg from "../../images/icons/close.svg";
import { ContextProviderComponent } from "../contextProviderComponent";

export default function OnboardingModalComponent(props) {
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const [modalChecked, setModalChecked] = useState(false);
  const context = useContext(ContextProviderComponent);
  const [modalText, setModalText] = useState({
    headerText: "",
    body: "",
    linkText: "",
    confirmText: "",
    submitText: ""
  });

  useEffect(() => {
    fillModalText();
    setShowOnboardingModal(props.showOnboardingModal);
  }, [props.showOnboardingModal]);

  function buttonClick() {
    window.open(
      `https://app.smartwealth.ch/${
        context.lang == "de-ch" ? "de" : "en"
      }/onboarding`
    );
  }

  function fillModalText() {
    if (context.lang == "de-ch") {
      setModalText({
        headerText:
          "Bitte lesen Sie sorgfältig die folgende Erklärung bevor Sie fortfahren:",
        body: `Smart Wealth AG ist ausschließlich in der Schweiz zur Erbringung von Anlageberatung und Vermögensverwaltung zugelassen. 
        Die digitale Kontoeröffnung ist in Abstimmung mit unserer Bank daher im Moment ausschließlich für Schweizer Kunden möglich.
        Sie haben Ihren Wohnsitz nicht in der Schweiz und möchten aber trotzdem Kunde der Smart Wealth AG werden, dann`,
        linkText: "setzen Sie sich mit uns in Verbindung.",
        confirmText: "Ich habe die Bedingungen gelesen und verstanden.",
        submitText: "Einreichen"
      });
    } else {
      setModalText({
        headerText:
          "Please read the following statement carefully before proceeding:",
        body: `Smart Wealth AG is licensed to provide investment advice and wealth management services in Switzerland only. 
          In coordination with our bank, digital account opening is therefore only available to Swiss clients at the moment.
          If you do not domicile in Switzerland but would still like to become a client of Smart Wealth AG,`,
        linkText: "get in touch with us.",
        confirmText: "I have read and understood the terms.",
        submitText: "SUBMIT"
      });
    }
  }

  return (
    <div
      className="onboardingModal"
      style={{ display: showOnboardingModal ? "flex" : "none" }}
    >
      <div className="onboardingModal_cont">
        <img
          src={closeImg}
          alt={"close cross"}
          onClick={() => props.setShowOnboardingModal(!showOnboardingModal)}
          className="onboardingModal_cont_closeBtn"
        ></img>
        <h2 className="onboardingModal_cont_header">{modalText.headerText}</h2>
        <span className="onboardingModal_cont_body">{modalText.body}</span>
        <span
          className="onboardingModal_cont_link"
          onClick={() => context.callBackForCalendlyModalShow("flex")}
        >
          {modalText.linkText}
        </span>

        <div className="onboardingModal_cont_submitCont">
          <div className="onboardingModal_cont_submitCont_checkBoxWrapper">
            <input
              type="checkbox"
              className="onboardingModal_cont_submitCont_checkBoxWrapper_checkbox"
              value={modalChecked}
              onChange={() => setModalChecked(!modalChecked)}
            />
            <span className="onboardingModal_cont_submitCont_checkBoxWrapper_text">
              {modalText.confirmText}
            </span>
          </div>
          <button
            disabled={!modalChecked}
            onClick={buttonClick}
            className="button onboardingModal_cont_submitCont_button"
          >
            <p>{modalText.submitText}</p>
          </button>
        </div>
      </div>
    </div>
  );
}
