import $ from "jquery";
import { TweenMax as TM, ScrollToPlugin, Power2, Power3 } from "gsap/all";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";

export const submitForm = async data => {
  // testing API on localhost server
  //const apiUrl = "http://localhost:8081/mailchimp/addContact";

  // testing API on platform server
  const apiUrl = `${process.env.API_DOMAIN}/mailchimp/addContact`;

  const formData = {
    source: "web_smartwealth",
    formType: "smartwealth_contact",
    listId: "9607ce5e77",
    name: data.name,
    email_address: data.email,
    phone: data.phone,
    message: data.message,
    callMe: data.callMe
  };

  postXHR(apiUrl, formData)
    .then(function(response) {
      if (!response.error) {
        $(".contact__form > form").fadeOut(function() {
          $(".contact__form > .thank__you").css("display", "flex");

          TM.to($(".contact__form > .thank__you"), 0.8, {
            opacity: 1,
            ease: Power2.easeOut,
            delay: 0.2
          });
        });
      }
    })
    .catch(function(error) {
      console.log(error);
    });

  trackCustomEvent({
    // string - required - The object that was interacted with (e.g.video)
    category: "Submited contact form",
    // string - required - Type of interaction (e.g. 'play')
    action: "Click",
    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
    label: "Smartwealth - Submited contact form",
    // number - optional - Numeric value associated with the event. (e.g. A product ID)
    value: 1
  });
};

const postXHR = function(url, data) {
  const request = new XMLHttpRequest();

  return new Promise(function(resolve, reject) {
    request.onreadystatechange = function() {
      if (request.readyState !== 4) return;
      if (request.status >= 200 && request.status < 300) {
        resolve(request.response);
      } else {
        reject({
          status: request.status,
          statusText: request.statusText
        });
      }
    };
    request.open("POST", url, true);
    request.setRequestHeader("Content-Type", "application/json");
    request.send(JSON.stringify(data));
  });
};
