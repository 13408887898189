import React from "react";
import "./footer.scss";
import twitterImg from "../../images/icons/Twitter.svg";
import youtubeImg from "../../images/icons/Youtube.svg";
import linkedinImg from "../../images/icons/LinkedIn.svg";
import aoosImg from "../../images/icons/footer_aoos.svg";
import logo from "../../images/logo/logo.png";
import { OutboundLink } from "gatsby-plugin-google-analytics";

import { Link } from "gatsby";

import PrivacyPolicyDE from "./PrivacyPolicy_DE.pdf";
import PrivacyPolicyEN from "./PrivacyPolicy_DE.pdf";
import ClientInformationDE from "./Kundeninformation_DE.pdf";
import ClientInformationEN from "./Kundeninformation_DE.pdf";

import {
  isMobile,
  isTablet,
  isIPad13,
  isDesktop,
  isBrowser,
  isMobileOnly
} from "react-device-detect";

import { TweenMax as TM, ScrollToPlugin, Power2, Power3 } from "gsap/all";
import { ContextProviderComponent } from "../contextProviderComponent";
import { ArrowLeft } from "../../../.cache/fast-refresh-overlay/helpers/keys";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageData: {
        about: { text: "" },
        item1: { text: "" },
        item2: { text: "" },
        item3: { text: "" },
        item4: { text: "" },
        item5: { text: "" },
        item6: { text: "" },
        item7: { text: "" },
        allrights: { text: "" },
        terms: { text: "" },
        privacy: { text: "" },
        clientinformation: { text: "" },
        impressum: { text: "" }
      },
      activeDocMeta: {
        lang: "",
        alternateLanguages: [{ lang: "", type: "" }]
      }
    };
  }

  componentDidMount() {
    let footer = document.getElementsByClassName("footer");

    TM.fromTo(
      footer,
      1,
      { opacity: 0 },
      {
        opacity: 1,
        ease: Power2.easeOut,
        delay: 0.6
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeSection !== this.props.activeSection) {
      this.setState({ activeSection: nextProps.activeSection });
    }
    if (nextProps.body) {
      this.setState({ pageData: { ...nextProps.body.data } });
    }
    if (nextProps.activeDocMeta) {
      this.setState({
        activeDocMeta: {
          lang: nextProps.activeDocMeta.lang,
          alternateLanguages: [...nextProps.activeDocMeta.alternateLanguages]
        }
      });
    }
  }

  render() {
    return (
      <footer id="footer" className="footer">
        {((isDesktop && !isTablet) ||
          (isTablet && window.innerWidth > window.innerHeight)) && (
          <div className="footer__cont">
            <div>
              <div className="footer__cont__firstRow">
                <img
                  onClick={() => this.context.navigateClickCallback(99)}
                  className="footer__cont__firstRow_logo"
                  alt="logo smartwealth"
                  src={logo}
                ></img>
              </div>
              <div className="footer__cont__secondRow">
                <div className="footer__cont__secondRow_column">
                  <p>{this.state.pageData.about.text}</p>
                  <img
                    src={aoosImg}
                    alt="logo aoos"
                    className="footer__cont__aoosImg"
                  />
                </div>
                <div className="footer__cont__secondRow_columns">
                  <div className="footer__cont__secondRow_column">
                    <span
                      className={
                        "link " +
                        (this.state.activeSection == 0 ? "link-active" : "")
                      }
                      onClick={() => this.context.navigateClickCallback(0)}
                    >
                      {this.state.pageData.item1.text}
                    </span>

                    {false && (
                      <span
                        className={
                          "link " +
                          (this.state.activeSection == 1 ? "link-active" : "")
                        }
                        onClick={() => console.log(1)}
                      >
                        {this.state.pageData.item2.text}
                      </span>
                    )}

                    {false && (
                      <span
                        className={
                          "link " +
                          (this.state.activeSection == 2 ? "link-active" : "")
                        }
                        onClick={() => console.log(2)}
                      >
                        {this.state.pageData.item3.text}
                      </span>
                    )}

                    <span
                      className={
                        "link " +
                        (this.state.activeSection == 3 ? "link-active" : "")
                      }
                      onClick={() => this.context.navigateClickCallback(2)}
                    >
                      {this.state.pageData.item4.text}
                    </span>
                  </div>
                  <div className="footer__cont__secondRow_column">
                    <span
                      className={
                        "link " +
                        (this.state.activeSection == 4 ? "link-active" : "")
                      }
                      onClick={() => this.context.navigateClickCallback(4)}
                    >
                      {this.state.pageData.item7.text}
                    </span>

                    {false && (
                      <span
                        className={
                          "link " +
                          (this.state.activeSection == 5 ? "link-active" : "")
                        }
                        onClick={() => console.log(5)}
                      >
                        {this.state.pageData.item6.text}
                      </span>
                    )}

                    {false && (
                      <span
                        className={
                          "link " +
                          (this.state.activeSection == 6 ? "link-active" : "")
                        }
                        onClick={() => console.log(6)}
                      >
                        {this.state.pageData.item5.text}
                      </span>
                    )}
                  </div>
                  <div className="footer__cont__secondRow_column">
                    <span>Zurich</span>
                    <span>Smart Wealth Asset Management AG</span>
                    <span>Bellerivestrasse 18</span>
                    <span>CH-8008 Zurich</span>
                    <span>Switzerland</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="footer__cont__thirdRow">
              <div className="footer__cont__thirdRow_copyright">
                <span>
                  © {new Date().getFullYear()} Smart Wealth Asset Management AG.{" "}
                  {this.state.pageData.allrights.text}
                </span>
                {false && (
                  <span className="link">{this.state.pageData.terms.text}</span>
                )}
                <span
                  className="link"
                  onClick={() => this.context.navigateClickCallback(8)}
                >
                  {this.state.pageData.privacy.text}
                </span>
                <span
                  className="link"
                  onClick={() => this.context.navigateClickCallback(9)}
                >
                  {this.state.pageData.clientinformation.text}
                </span>
                <span
                  className="link"
                  onClick={() => this.context.navigateClickCallback(10)}
                >
                  {this.state.pageData.impressum.text
                    ? this.state.pageData.impressum.text
                    : "Impressum"}
                </span>
              </div>
              <div className="footer__cont__thirdRow_social">
                <a
                  href="https://www.linkedin.com/company/18721600"
                  target="_blank"
                >
                  <img src={linkedinImg} alt={"linkedin"}></img>
                </a>
                <a href="https://twitter.com/SmartWealthAG" target="_blank">
                  <img src={twitterImg} alt={"twitter"}></img>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCp8p5oGuDJZDonWePKQmeUQ"
                  target="_blank"
                >
                  <img src={youtubeImg} alt={"youtube"}></img>
                </a>
              </div>
            </div>
          </div>
        )}

        {(isMobileOnly ||
          (isTablet && window.innerHeight > window.innerWidth)) && (
          <div className="footer__cont">
            <div className="footer__cont__secondRow_columns">
              <div className="footer__cont__secondRow_column">
                <span
                  className="link"
                  onClick={() => this.context.navigateClickCallback(0)}
                >
                  {this.state.pageData.item1.text}
                </span>
                {false && (
                  <span className="link">{this.state.pageData.item2.text}</span>
                )}
                {false && (
                  <span className="link">{this.state.pageData.item3.text}</span>
                )}
                <span
                  className="link"
                  onClick={() => this.context.navigateClickCallback(2)}
                >
                  {this.state.pageData.item4.text}
                </span>
                <span
                  className="link"
                  onClick={() => this.context.navigateClickCallback(4)}
                >
                  {this.state.pageData.item7.text}
                </span>
              </div>
              {false && (
                <div className="footer__cont__secondRow_column">
                  <span className="link">{this.state.pageData.item5.text}</span>
                  <span className="link">{this.state.pageData.item6.text}</span>
                  <span className="link">{this.state.pageData.item7.text}</span>
                </div>
              )}
              <div className="footer__cont__secondRow_column">
                {false && (
                  <span className="link">{this.state.pageData.terms.text}</span>
                )}
                <span
                  className="link"
                  onClick={() => this.context.navigateClickCallback(8)}
                >
                  {this.state.pageData.privacy.text}
                </span>
                <span
                  className="link"
                  onClick={() => this.context.navigateClickCallback(9)}
                >
                  {this.state.pageData.clientinformation.text}
                </span>
                <span
                  className="link"
                  onClick={() => this.context.navigateClickCallback(10)}
                >
                  {this.state.pageData.impressum.text
                    ? this.state.pageData.impressum.text
                    : "Impressum"}
                </span>
              </div>
              <div className="footer__cont__secondRow_column">
                <span>Zurich</span>
                <span>Smart Wealth Asset Management AG</span>
                <span>Bellerivestrasse 18</span>
                <span>CH-8008 Zurich</span>
                <span>Switzerland</span>
              </div>
            </div>

            <div className="footer__cont__firstRow">
              <Link to="/" className="footer__cont__firstRow_a" target="_blank">
                <img
                  className="footer__cont__firstRow_logo"
                  src={logo}
                  alt="logo smartweatlth"
                ></img>
              </Link>
            </div>

            <div className="footer__cont__secondRow">
              <p>{this.state.pageData.about.text}</p>
              <img
                src={aoosImg}
                className="footer__cont__aoosImg"
                alt="logo aoos"
              />
            </div>

            <div className="footer__cont__thirdRow">
              <div className="footer__cont__thirdRow_social">
                <a
                  href="https://www.linkedin.com/company/18721600"
                  target="_blank"
                >
                  <img src={linkedinImg} alt={"linkedin"}></img>
                </a>
                <a href="https://twitter.com/SmartWealthAG" target="_blank">
                  <img src={twitterImg} alt={"twitter"}></img>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCp8p5oGuDJZDonWePKQmeUQ"
                  target="_blank"
                >
                  <img src={youtubeImg} alt={"youtube"}></img>
                </a>
              </div>
            </div>

            <div className="footer__cont__thirdRow_copyright">
              <span>© {new Date().getFullYear()} Smart Wealth Asset Management AG.&nbsp;</span>
              <span>{this.state.pageData.allrights.text}</span>
            </div>
          </div>
        )}
      </footer>
    );
  }
}
Footer.contextType = ContextProviderComponent;
export default Footer;
