import React from "react";
import $ from "jquery";
import Observer from "react-intersection-observer";
import "./solutionsComponent.scss";
import { TweenMax as TM, Power2, Power3 } from "gsap/all";
import { ContextProviderComponent } from "../../contextProviderComponent";

import solutionsBackground from "../../../images/homepage/solutions_background.jpg";
import {
  isBrowser,
  isMobileOnly,
  isMobile,
  isTablet,
  isDesktop
} from "react-device-detect";

class SolutionsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionNumber: this.props.slice.primary.section_number.text || "",
      sectionName: this.props.slice.primary.section_name.text || "",
      background: this.props.slice.primary.background.url
        ? this.props.slice.primary.background.url.split("?")[0]
        : solutionsBackground,
      title1: this.props.slice.primary.title1.text || "",
      title2: this.props.slice.primary.title2.text || "",
      title3: this.props.slice.primary.title3.text || "",
      text1: this.props.slice.primary.text1.text || "",
      text2: this.props.slice.primary.text2.text || "",
      text3: this.props.slice.primary.text3.text || "",
      button: this.props.slice.primary.button.text || "",
      buttonLink: this.props.slice.primary.button_link
        ? this.props.slice.primary.button_link.url
        : ""
    };
  }

  componentDidMount() {
    if (isBrowser && !isTablet) {
      let widths = [];

      $(".solutionsSection__cont_content_item")
        .find("h5")
        .each((index, el) => {
          widths.push($(el).width());
        });

      $(".solutionsSection__cont_content_item").css(
        "width",
        Math.max(...widths)
      );

      /////////////////////

      var timer1;

      $(".solutionsSection__cont_content_item")
        .find("h5")
        .on({
          mouseover: function(event) {
            timer1 = setTimeout(function() {
              $(event.target)
                .next()
                .slideDown(200, "linear");
            }, 400);
          },
          mouseout: function(event) {
            clearTimeout(timer1);
            $(event.target)
              .next()
              .slideUp(200, "linear");
          }
        });
    }
  }

  handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(entry.target.firstChild, 0.8, {
        opacity: 1,
        ease: Power2.easeInOut,
        delay: 0.0
      });
    }
  }

  render() {
    return (
      <section
        id="solutionsSection"
        className="solutionsSection"
        style={{
          background: "url('" + this.state.background + "')",
          backgroundPosition: isDesktop && !isTablet ? "0% 0%" : "52% 50%",
          backgroundSize: "cover",
          backgroundColor: "#E5E5E5"
        }}
      >
        <Observer
          threshold="0.1"
          onChange={(inView, entry) =>
            this.handleContentVisibility(inView, entry)
          }
          className="wrapper"
        >
          <div className="solutionsSection__cont">
            <div className="solutionsSection__cont_pageNumber">
              <span>{this.state.sectionNumber}</span>
            </div>
            <div className="solutionsSection__cont_content">
              <span>
                {isMobileOnly ||
                (isTablet && window.innerHeight > window.innerWidth) ? (
                  <span style={{ marginRight: "24px" }}>
                    {this.state.sectionNumber}
                  </span>
                ) : (
                  ""
                )}
                {this.state.sectionName}
              </span>

              <div
                className="solutionsSection__cont_content_item"
                onClick={() => this.context.navigateClickCallback(11)}
              >
                <h5>{this.state.title1}</h5>
                <p>{this.state.text1}</p>
              </div>

              <div
                className="solutionsSection__cont_content_item"
                onClick={() => this.context.navigateClickCallback(12)}
              >
                <h5>{this.state.title2}</h5>
                <p>{this.state.text2}</p>
              </div>

              <div
                className="solutionsSection__cont_content_item"
                onClick={() => this.context.navigateClickCallback(13)}
              >
                <h5>{this.state.title3}</h5>
                <p>{this.state.text3}</p>
              </div>

              {this.state.button && this.state.button !== "" && (
                <div className="solutionsSection__cont_content_item">
                  <div
                    className="button"
                    onClick={() =>
                      this.state.buttonLink != ""
                        ? window.open(this.state.buttonLink, "_blank")
                        : this.context.callBackForCalendlyModalShow("flex")
                    }
                  >
                    <p>{this.state.button}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Observer>
      </section>
    );
  }
}
SolutionsComponent.contextType = ContextProviderComponent;
export default SolutionsComponent;
