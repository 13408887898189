import React from "react";
import "./ourSolutionsComponent.scss";
import Observer from "react-intersection-observer";

import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap/all";
import {
  isBrowser,
  isMobileOnly,
  isMobile,
  isTablet,
  isDesktop
} from "react-device-detect";
import { ContextProviderComponent } from "../../contextProviderComponent";
import StrategyBasicTab from "../strategyComponent/strategyBasicTab/strategyBasicTab";
import StrategySliderTab from "../strategyComponent/strategySliderTab/strategySliderTab";

import img_offshore from "../../../images/b2b/offshore.webp";
import img_exotix_1 from "../../../images/b2b/exotic_1.webp";
import img_exotix_2 from "../../../images/b2b/exotic_2.webp";
import img_exotix_3 from "../../../images/b2b/exotic_3.webp";
import img_exotix_4 from "../../../images/b2b/exotic_4.webp";
import img_exotix_5 from "../../../images/b2b/exotic_5.webp";
import img_private_1 from "../../../images/b2b/private_1.webp";
import img_private_2 from "../../../images/b2b/private_2.webp";
import img_realestate_1 from "../../../images/b2b/realestate_1.webp";
import img_realestate_2 from "../../../images/b2b/realestate_2.webp";
import img_debt from "../../../images/b2b/debt.webp";

var slides_en = [
  {
    tab: "Offshore fund",
    title: "Offshore fund securitization",
    text:
      "Making these funds easily accessible to institutional clients and qualified investors through feeder solutions",
    images: img_offshore
  },
  {
    tab: "Exotic Assets",
    title: "Art, IP-Rights and other Exotic Assets",
    text: [
      "Raising funds for investment in art and exotic assets or transferring rights to assets in order to exploit them without giving up control or exploiting them.",
      "Collecting and driving classic cars can be a good investment. Investors in classic car securitisations receive a share of the profits from the sale of the vehicles if the values of classic cars continue to rise.",
      "By selling rights to future cash flows from an asset, such as royalties from a patent or copyright, the value of the asset is paid out in advance.",
      "Investing via securitization in young horses for training to jump or race with a later sale can be an incredibly profitable business and can help you diversify your portfolio.",
      "Athletes are often considered one of the most valuable assets in the world. They can generate huge revenues in their careers. Athlete securitisation has great potential and can provide a new source of funding for athletes."
    ],
    images: [
      img_exotix_1,
      img_exotix_2,
      img_exotix_3,
      img_exotix_4,
      img_exotix_5
    ]
  },
  {
    tab: "Private Equity",
    title: "Private Equity and Pre-IPO",
    text: [
      "Convert direct private equity investments into bankable assets with ISINs and clearing capability or reduce large holdings and still benefit from share price increases.",
      "Gain higher liquidity in investment property for investors and get access to a broader investor base."
    ],
    images: [img_private_1, img_private_2]
  },
  {
    tab: "Real Estate",
    title: "Real Estate",
    text: [
      "The innovative way to raise capital, to finance a real estate development project or to take the real estate portfolio off a balance sheet.",
      "Turning real estate projects into tradable securities. The process is efficient and flexible, and it can be used to finance any type of project."
    ],
    images: [img_realestate_1, img_realestate_2]
  },
  {
    tab: "Private Debt",
    title: "Private Debt",
    text:
      "Private loans can be securitised into any type of bonds, including convertibles. This is a highly efficient way to access institutional fixed-income investors worldwide.",
    images: img_debt
  }
];

//###########################GERMAN TEXTS######################

var slides_de = [
  {
    tab: "Offshore-Fonds",
    title: "Verbriefung von Offshore-Fonds",
    text:
      "Erleichterter Zugang zu diesen Fonds für institutionelle Kunden und qualifizierte Anleger durch Feeder-Lösungen",
    images: img_offshore
  },
  {
    tab: "Exotic Assets",
    title: "Kunst, IP-Rechte und andere exotische Vermögenswerte",
    text: [
      "Kapitalbeschaffung für Investitionen in Kunst und exotischen Vermögenswerten oder Abtretung von Rechten an Vermögenswerten, um sie zu veräußern, ohne die Kontrolle aufzugeben.",
      "Sammeln und Fahren von Oldtimern kann auch eine gute Geldanlage sein, insbesondere bei seltenen und begehrten Modellen. Anleger in Oldtimer-Verbriefungen erhalten einen Anteil an den Gewinnen aus dem Verkauf der Fahrzeuge, wenn die Werte von Oldtimern weiter steigen.",
      "Durch den Verkauf von Rechten auf künftige Cashflows aus einem Vermögenswert, wie z. B. Lizenzgebühren aus einem Patent oder Urheberrecht wird der Wert des Vermögenswertes im Voraus  ausgezahlt.",
      "Die Verbriefung von Investitionen in junge Spring- oder Rennpferde, die später verkauft werden sollen, kann ein unglaublich rentables Geschäft sein und Ihnen helfen, Ihr Portfolio zu diversifizieren.",
      "Athleten werden oft als eines der wertvollsten Güter der Welt betrachtet. Sie können in Ihrer Karrieren enorme Einnahmen generieren. Die Verbriefung von Sportlern hat ein großes Potenzial und kann eine neue Finanzierungsquelle für Sportler bieten."
    ],
    images: [
      img_exotix_1,
      img_exotix_2,
      img_exotix_3,
      img_exotix_4,
      img_exotix_5
    ]
  },
  {
    tab: "Private Equity",
    title: "Private Equity and Pre-IPO",
    text: [
      "Direkte Privat Equity Anlagen  in bankfähige Vermögenswerte mit ISIN und Clearingfähigkeit konvertieren oder große Beteiligungen reduzieren und trotzdem an Kurssteigerungen profitieren.",
      "Erhalten Sie höhere Liquidität in Anlageobjekt für die Investoren und Zugang zu einer breiteren Anlegerbasis."
    ],
    images: [img_private_1, img_private_2]
  },
  {
    tab: "Immobilien",
    title: "Immobilien",
    text: [
      "Der innovative Weg zur Kapitalbeschaffung, zur Finanzierung eines Immobilienentwicklungsprojekts oder um das Immobilienportfolio aus einer Bilanz zu nehmen.",
      "Immobilienprojekte in handelbare Wertpapiere umwandeln. Das Prinzip ist effizient und flexibel und kann für die Finanzierung jeder Art von Projekt genutzt werden."
    ],
    images: [img_realestate_1, img_realestate_2]
  },
  {
    tab: "Private Debt",
    title: "Private Debt",
    text:
      "Private Kredite können in jeder Art von Anleihen verbrieft werden, auch in Wandelanleihen. Dies ist ein äußerst effizienter Weg, um Zugang zu institutionellen festverzinslichen Anlegern weltweit zu erhalten.",
    images: img_debt
  }
];

class OurSolutionsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionNumber: this.props.slice.primary.section_number.text || "",
      sectionName: this.props.slice.primary.section_title.text || "",
      title: this.props.slice.primary.title.text,
      activeTab: 0,
      data: slides_de
    };
  }

  componentDidMount() {
    this.setState({ index: 0 });
    this.setState({
      data: this.context.lang == "de-ch" ? slides_de : slides_en
    });
  }

  handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(this.title, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.4
      });
    }
  }

  changeTab = number => {
    TM.to(this.tabContainer, 0.3, {
      opacity: 0,
      ease: Power2.easeOut,
      delay: 0,
      onComplete: function() {
        this.setState({ activeTab: number });
        TM.to(this.tabContainer, 0.3, {
          opacity: 1,
          ease: Power2.easeOut,
          delay: 0.1
        });
      }.bind(this)
    });
  };

  render() {
    return (
      <section
        id="ourSolutionsComponent"
        className={`ourSolutionsComponent ${
          this.state.orientation == "Left" ? "" : "ourSolutionsComponent_white"
        }`}
      >
        <Observer
          threshold="0.1"
          onChange={(inView, entry) =>
            this.handleContentVisibility(inView, entry)
          }
          className="wrapper"
        >
          <div className="ourSolutionsComponent__cont">
            <div className="ourSolutionsComponent__cont_pageNumber">
              <span>{this.state.sectionNumber}</span>
            </div>
            <div className="ourSolutionsComponent__cont_content">
              <span className="ourSolutionsComponent__cont_content_pageTitle">
                {isMobileOnly ||
                (isTablet && window.innerHeight > window.innerWidth) ? (
                  <span style={{ marginRight: "24px" }}>
                    {this.state.sectionNumber}
                  </span>
                ) : (
                  ""
                )}
                {this.state.sectionName}
              </span>
              <h2
                ref={ref => {
                  this.title = ref;
                }}
                className="ourSolutionsComponent__cont_content_title"
              >
                {this.state.title}
              </h2>
              <div className="ourSolutionsComponent__cont_content_tabsContainer">
                <div
                  className={`ourSolutionsComponent__cont_content_tabsContainer_tab ${
                    this.state.activeTab == 0
                      ? "ourSolutionsComponent__cont_content_tabsContainer_tab-active"
                      : ""
                  }`}
                  onClick={() => this.changeTab(0)}
                >
                  {this.state.data[0].tab}
                </div>
                <div
                  className={`ourSolutionsComponent__cont_content_tabsContainer_tab ${
                    this.state.activeTab == 1
                      ? "ourSolutionsComponent__cont_content_tabsContainer_tab-active"
                      : ""
                  }`}
                  onClick={() => this.changeTab(1)}
                >
                  {this.state.data[1].tab}
                </div>
                <div
                  className={`ourSolutionsComponent__cont_content_tabsContainer_tab ${
                    this.state.activeTab == 2
                      ? "ourSolutionsComponent__cont_content_tabsContainer_tab-active"
                      : ""
                  }`}
                  onClick={() => this.changeTab(2)}
                >
                  {this.state.data[2].tab}
                </div>
                <div
                  className={`ourSolutionsComponent__cont_content_tabsContainer_tab ${
                    this.state.activeTab == 3
                      ? "ourSolutionsComponent__cont_content_tabsContainer_tab-active"
                      : ""
                  }`}
                  onClick={() => this.changeTab(3)}
                >
                  {this.state.data[3].tab}
                </div>
                <div
                  className={`ourSolutionsComponent__cont_content_tabsContainer_tab ${
                    this.state.activeTab == 4
                      ? "ourSolutionsComponent__cont_content_tabsContainer_tab-active"
                      : ""
                  }`}
                  onClick={() => this.changeTab(4)}
                >
                  {this.state.data[4].tab}
                </div>
              </div>
              <div
                ref={ref => {
                  this.tabContainer = ref;
                }}
              >
                {this.state.activeTab == 0 && (
                  <StrategyBasicTab
                    title={this.state.data[this.state.activeTab].title}
                    text={this.state.data[this.state.activeTab].text}
                    image={this.state.data[this.state.activeTab].images}
                    showButton={false}
                  />
                )}
                {this.state.activeTab == 1 && (
                  <StrategySliderTab
                    title={this.state.data[this.state.activeTab].title}
                    text={this.state.data[this.state.activeTab].text}
                    imageSlides={this.state.data[this.state.activeTab].images}
                    showButton={false}
                  />
                )}
                {this.state.activeTab == 2 && (
                  <StrategySliderTab
                    title={this.state.data[this.state.activeTab].title}
                    text={this.state.data[this.state.activeTab].text}
                    imageSlides={this.state.data[this.state.activeTab].images}
                    showButton={false}
                  />
                )}
                {this.state.activeTab == 3 && (
                  <StrategySliderTab
                    title={this.state.data[this.state.activeTab].title}
                    text={this.state.data[this.state.activeTab].text}
                    imageSlides={this.state.data[this.state.activeTab].images}
                    showButton={false}
                  />
                )}
                {this.state.activeTab == 4 && (
                  <StrategyBasicTab
                    title={this.state.data[this.state.activeTab].title}
                    text={this.state.data[this.state.activeTab].text}
                    image={this.state.data[this.state.activeTab].images}
                    showButton={false}
                  />
                )}
              </div>
            </div>
          </div>
        </Observer>
      </section>
    );
  }
}
OurSolutionsComponent.contextType = ContextProviderComponent;
export default OurSolutionsComponent;
