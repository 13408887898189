import React from "react";
import "./blogFormattedTextBlock.scss";
import Observer from "react-intersection-observer";
import { TweenMax as TM, Power2, Power3 } from "gsap/all";
import { ContextProviderComponent } from "../../contextProviderComponent";
import { Link } from "gatsby";
import { isMobile, isTablet, isDesktop } from "react-device-detect";
import parse from "html-react-parser";

class BlogFormattedTextBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: this.props.slice.primary.formattedtextblock || "",
      title: this.props.slice.primary.title1 || ""
    };
  }

  componentDidMount() {}

  handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(this.text, 0.8, {
        opacity: 1,
        ease: Power2.easeInOut,
        delay: 0.0
      });
    }
  }

  render() {
    return (
      <section id="blogFormattedTextBlock" className="blogFormattedTextBlock">
        <Observer
          threshold="0.0"
          onChange={(inView, entry) =>
            this.handleContentVisibility(inView, entry)
          }
          className="wrapper"
        >
          <div
            className="blogFormattedTextBlock_cont"
            ref={ref => (this.text = ref)}
          >
            <h3>{this.state.title?.text}</h3>
            {parse(this.state.text?.html)}
          </div>
        </Observer>
      </section>
    );
  }
}
BlogFormattedTextBlock.contextType = ContextProviderComponent;
export default BlogFormattedTextBlock;
