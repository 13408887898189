import React from "react";
import "./advantagesComponent.scss";
import Observer from "react-intersection-observer";
import card1Img from "../../../images/privatkunden/percent_icon.svg";
import card2Img from "../../../images/privatkunden/shield_icon.svg";
import card3Img from "../../../images/privatkunden/chart_icon.svg";
import card4Img from "../../../images/privatkunden/percent_icon.svg";

import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap/all";
import { ContextProviderComponent } from "../../contextProviderComponent";

class AdvantagesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.slice.primary.title.text,
      subtitle: this.props.slice.primary.subtitle.text,
      button_text: this.props.slice.primary.button_text.text,
      buttonLink: this.props.slice.primary.button_link
        ? this.props.slice.primary.button_link.url
        : ""
    };
  }

  componentDidMount() {
    this.setState({ index: 0 });
    gsap.set(
      [
        this.title,
        this.subtitle,
        this.button,
        this.card1,
        this.card2,
        this.card3,
        this.card4
      ],
      { opacity: 0, y: 50 }
    );
  }

  handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(this.title, 0.5, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0
      });
      TM.to(this.subtitle, 0.5, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.2
      });
      TM.to(this.button, 0.5, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.4
      });
      TM.to(this.card1, 0.5, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.6
      });
      TM.to(this.card2, 0.5, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.7
      });
      TM.to(this.card3, 0.5, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.8
      });
      TM.to(this.card4, 0.5, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.9
      });
    }
  }

  render() {
    return (
      <section id="advantages" className="advantages">
        <Observer
          threshold="0.4"
          onChange={(inView, entry) =>
            this.handleContentVisibility(inView, entry)
          }
          className="wrapper"
        >
          <div className="advantages__cont">
            <h2
              ref={ref => {
                this.title = ref;
              }}
              className="advantages__cont_title"
            >
              {this.state.title}
            </h2>
            <h3
              ref={ref => {
                this.subtitle = ref;
              }}
              className="advantages__cont_subtitle"
            >
              {this.state.subtitle}
            </h3>
            <div
              ref={ref => {
                this.button = ref;
              }}
              id="heroTextBtn"
              className="hero__cont_button button advantages__cont_button"
              onClick={() =>
                this.state.buttonLink != ""
                  ? window.open(this.state.buttonLink, "_blank")
                  : this.context.navigateClickCallback(4)
              }
            >
              {this.state.button_text && <p>{this.state.button_text}</p>}
            </div>
            <div className="advantages__cont_advantagesCont">
              <div className="advantages__cont_advantagesCont_row">
                <div
                  className="advantages__cont_advantagesCont_row_card"
                  ref={ref => {
                    this.card1 = ref;
                  }}
                >
                  <div className="advantages__cont_advantagesCont_row_card_leftCont">
                    <img alt={"smartwealth image advantage"} src={card1Img} />
                  </div>
                  <div className="advantages__cont_advantagesCont_row_card_rightCont">
                    <h4>{this.props.slice.items[0].title.text}</h4>
                    <p>{this.props.slice.items[0].text.text}</p>
                  </div>
                </div>
                <div
                  className="advantages__cont_advantagesCont_row_card"
                  ref={ref => {
                    this.card2 = ref;
                  }}
                >
                  <div className="advantages__cont_advantagesCont_row_card_leftCont">
                    <img alt={"smartwealth image advantage"} src={card2Img} />
                  </div>
                  <div className="advantages__cont_advantagesCont_row_card_rightCont">
                    <h4>{this.props.slice.items[1].title.text}</h4>
                    <p>{this.props.slice.items[1].text.text}</p>
                  </div>
                </div>
              </div>
              <div className="advantages__cont_advantagesCont_row">
                <div
                  className="advantages__cont_advantagesCont_row_card"
                  ref={ref => {
                    this.card3 = ref;
                  }}
                >
                  <div className="advantages__cont_advantagesCont_row_card_leftCont">
                    <img alt={"smartwealth image advantage"} src={card3Img} />
                  </div>
                  <div className="advantages__cont_advantagesCont_row_card_rightCont">
                    <h4>{this.props.slice.items[2].title.text}</h4>
                    <p>{this.props.slice.items[2].text.text}</p>
                  </div>
                </div>
                <div
                  className="advantages__cont_advantagesCont_row_card"
                  ref={ref => {
                    this.card4 = ref;
                  }}
                >
                  <div className="advantages__cont_advantagesCont_row_card_leftCont">
                    <img alt={"smartwealth image advantage"} src={card4Img} />
                  </div>
                  <div className="advantages__cont_advantagesCont_row_card_rightCont">
                    <h4>{this.props.slice.items[3].title.text}</h4>
                    <p>{this.props.slice.items[3].text.text}</p>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </Observer>
      </section>
    );
  }
}
AdvantagesComponent.contextType = ContextProviderComponent;
export default AdvantagesComponent;
