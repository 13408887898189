import React from "react";
import $ from "jquery";
import Observer from "react-intersection-observer";
import "./beatBenchmarkComponent.scss";
import {
  TweenMax as TM,
  ScrollToPlugin,
  Power1,
  Power2,
  Power3,
  Power4
} from "gsap/all";
import { ContextProviderComponent } from "../../contextProviderComponent";

import lineVectorImg from "../../../images/homepage/line_vector.svg";
import { isMobile, isTablet, isDesktop } from "react-device-detect";

class BeatBenchmarkComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionNumber: this.props.slice.primary.section_number.text || "",
      sectionText: this.props.slice.primary.section_text.text || "",
      sectionHeading: this.props.slice.primary.section_heading.text || ""
    };
  }

  componentDidMount() {}

  handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(entry.target.firstChild, 0.8, {
        opacity: 1,
        ease: Power2.easeInOut,
        delay: 0.0
      });

      TM.to(
        $(entry.target.firstChild).find(
          ".beatBenchmarkSection__cont_vector_overlay"
        ),
        1,
        {
          left: "100vw",
          ease: Power1.easeOut,
          delay: 0.6
        }
      );
    }
  }

  render() {
    return (
      <section id="beatBenchmarkSection" className="beatBenchmarkSection">
        <Observer
          threshold="0.1"
          onChange={(inView, entry) =>
            this.handleContentVisibility(inView, entry)
          }
          className="wrapper"
        >
          <div className="beatBenchmarkSection__cont">
            <div className="beatBenchmarkSection__cont_content">
              <span>{this.state.sectionNumber}</span>
              <p>{this.state.sectionText}</p>
              <h2>{this.state.sectionHeading}</h2>
            </div>

            <div className="beatBenchmarkSection__cont_vector">
              <div className="beatBenchmarkSection__cont_vector_overlay"></div>
              <img src={lineVectorImg} alt="line vector"></img>
            </div>
          </div>
        </Observer>
      </section>
    );
  }
}
BeatBenchmarkComponent.contextType = ContextProviderComponent;
export default BeatBenchmarkComponent;
