import React from "react";
import "./protectYourWealthComponent.scss";
import Observer from "react-intersection-observer";
import dashboardImage from "../../../images/privatkunden/dashboardImage.png";

import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap/all";
import { ContextProviderComponent } from "../../contextProviderComponent";
import { Link } from "gatsby";

class ProtectYourWealthComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.slice.primary.title.text,
      subtitle: this.props.slice.primary.subtitle.text
    };
    this.itemsRefs = [this.props.slice.items.length];
  }

  componentDidMount() {
    this.setState({ index: 0 });
    gsap.set([this.title, this.subtitle], { opacity: 0, y: 50 });
    this.itemsRefs.forEach(item => {
      gsap.set(item, { opacity: 0, y: 50 });
    });
  }

  handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(this.title, 0.5, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.2
      });
      TM.to(this.subtitle, 0.5, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.35
      });
      var delay = 0.2;
      this.itemsRefs.forEach(item => {
        delay = delay + 0.2;
        TM.to(item, 0.5, {
          opacity: 1,
          y: 0,
          ease: Power3.easeOut,
          delay: delay
        });
      });
    }
  }

  render() {
    return (
      <section id="protectYourWealth" className="protectYourWealth">
        <Observer
          threshold="0.1"
          onChange={(inView, entry) =>
            this.handleContentVisibility(inView, entry)
          }
          className="wrapper"
        >
          <div className="protectYourWealth__cont">
            <div className="protectYourWealth__cont_left">
              <h2
                ref={ref => {
                  this.title = ref;
                }}
                className="protectYourWealth__cont_left_title"
              >
                {this.state.title}
              </h2>
              <h3
                ref={ref => {
                  this.subtitle = ref;
                }}
                className="protectYourWealth__cont_left_subtitle"
              >
                {this.state.subtitle}
              </h3>
            </div>
            <div className="protectYourWealth__cont_right">
              {this.props.slice.items.map((el, i) => {
                return (
                  <div
                    key={"card" + i}
                    ref={ref => (this.itemsRefs[i] = ref)}
                    className="protectYourWealth__cont_right_cell"
                  >
                    <div className="protectYourWealth__cont_right_cell_header">
                      <span>{"Step " + String(i + 1)}</span>
                    </div>
                    <p className="protectYourWealth__cont_right_cell_text">
                      {el.text.text}
                    </p>
                    <Link
                      to="#"
                      className="protectYourWealth__cont_right_cell_link"
                    >
                      <p>{el.link_text.text}</p>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </Observer>
      </section>
    );
  }
}
ProtectYourWealthComponent.contextType = ContextProviderComponent;
export default ProtectYourWealthComponent;
