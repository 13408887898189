import React from "react";
import "./customerinformationComponent.scss";
import Observer from "react-intersection-observer";
import { TweenMax as TM, Power2, Power3 } from "gsap/all";
import { ContextProviderComponent } from "../../contextProviderComponent";

import { Link } from "gatsby";

import { isMobile, isTablet, isDesktop } from "react-device-detect";

class CustomerInformationComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.slice.primary.title || "",
      text: this.props.slice.primary.text || ""
    };
  }

  componentDidMount() {}

  handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(entry.target.firstChild, 0.8, {
        opacity: 1,
        ease: Power2.easeInOut,
        delay: 0.0
      });
    }
  }

  render() {
    return (
      <section
        id="customerinformationSection"
        className="customerinformationSection"
      >
        <Observer
          threshold="0.0"
          onChange={(inView, entry) =>
            this.handleContentVisibility(inView, entry)
          }
          className="wrapper"
        >
          <div className="customerinformationSection__cont">
            {this.state.title &&
              this.state.title.map((title, i) => {
                return <h1 key={i}>{title.text}</h1>;
              })}
            {this.state.text &&
              this.state.text.map((text, i) => {
                return <p key={i}>{text.text}</p>;
              })}
          </div>
        </Observer>
      </section>
    );
  }
}
CustomerInformationComponent.contextType = ContextProviderComponent;
export default CustomerInformationComponent;
