import React from "react";
import "./pictureTextSliderComponent.scss";
import Observer from "react-intersection-observer";

import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap/all";
import {
  isBrowser,
  isMobileOnly,
  isMobile,
  isTablet,
  isDesktop
} from "react-device-detect";
import { ContextProviderComponent } from "../../contextProviderComponent";
import prevIcon from "../../../images/icons/prev.svg";
import nextIcon from "../../../images/icons/next.svg";
import $ from "jquery";

class PictureTextSliderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionNumber: this.props.slice.primary.section_number.text || "",
      sectionName: this.props.slice.primary.section_title.text || "",
      title: this.props.slice.primary.title.text,
      subtitle: this.props.slice.primary.description.text,
      slides: this.props.slice.items,
      orientation: this.props.slice.primary.picture_orientation,
      index: 0,
      wait: false
    };
  }

  componentDidMount() {
    this.setState({ index: 0 });
    gsap.set([this.title, this.subtitle], { opacity: 0, y: 50 });
    gsap.set(this.slider, { opacity: 0 });
  }

  slidePrevious = () => {
    this.setState({ wait: true });
    let newIndex = this.state.index - 1;

    if (newIndex < 0) {
      newIndex += this.state.slides.length;
    }

    TM.to(this.slideImg, 0.3, {
      x: "20px",
      opacity: 0,
      ease: Power2.easeOut,
      delay: 0,
      onComplete: function() {
        this.setState({ index: newIndex });

        TM.to(this.slideImg, 0.05, {
          x: "-20px",
          ease: Power2.easeOut,
          onComplete: function() {
            TM.to(this.slideImg, 0.3, {
              x: "0%",
              opacity: 1,
              ease: Power2.easeOut,
              delay: 0.3,
              onComplete: function() {
                this.setState({ wait: false });
              }.bind(this)
            });
          }.bind(this)
        });
      }.bind(this)
    });
    TM.to(this.slideText, 0.3, {
      x: "20px",
      opacity: 0,
      ease: Power2.easeOut,
      delay: 0,
      onComplete: function() {
        TM.to(this.slideText, 0.05, {
          x: "-20px",
          ease: Power2.easeOut,
          onComplete: function() {
            TM.to(this.slideText, 0.3, {
              x: "0%",
              opacity: 1,
              ease: Power2.easeOut,
              delay: 0.3
            });
          }.bind(this)
        });
      }.bind(this)
    });
  };

  slideNext = () => {
    this.setState({ wait: true });
    let newIndex = (this.state.index + 1) % this.state.slides.length;

    TM.to(this.slideImg, 0.3, {
      x: "-20px",
      opacity: 0,
      ease: Power2.easeOut,
      delay: 0,
      onComplete: function() {
        this.setState({ index: newIndex });

        TM.to(this.slideImg, 0.05, {
          x: "20px",
          ease: Power2.easeOut,
          onComplete: function() {
            TM.to(this.slideImg, 0.3, {
              x: "0%",
              opacity: 1,
              ease: Power2.easeOut,
              delay: 0.3,
              onComplete: function() {
                this.setState({ wait: false });
              }.bind(this)
            });
          }.bind(this)
        });
      }.bind(this)
    });
    TM.to(this.slideText, 0.3, {
      x: "-20px",
      opacity: 0,
      ease: Power2.easeOut,
      delay: 0,
      onComplete: function() {
        TM.to(this.slideText, 0.05, {
          x: "20px",
          ease: Power2.easeOut,
          onComplete: function() {
            TM.to(this.slideText, 0.3, {
              x: "0%",
              opacity: 1,
              ease: Power2.easeOut,
              delay: 0.3
            });
          }.bind(this)
        });
      }.bind(this)
    });
  };

  handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(this.title, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.4
      });
      TM.to(this.subtitle, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.5
      });
      TM.to(this.slider, 1, {
        opacity: 1,
        ease: Power3.easeOut,
        delay: 0.6
      });
    }
  }

  render() {
    return (
      <section
        id="pictureTextSliderComponent"
        className={`pictureTextSliderComponent ${
          this.state.orientation == "Left"
            ? ""
            : "pictureTextSliderComponent_white"
        }`}
      >
        <Observer
          threshold="0.1"
          onChange={(inView, entry) =>
            this.handleContentVisibility(inView, entry)
          }
          className="wrapper"
        >
          <div className="pictureTextSliderComponent__cont">
            <div className="pictureTextSliderComponent__cont_pageNumber">
              <span>{this.state.sectionNumber}</span>
            </div>
            <div className="pictureTextSliderComponent__cont_content">
              <span className="pictureTextSliderComponent__cont_content_pageTitle">
                {isMobileOnly ||
                (isTablet && window.innerHeight > window.innerWidth) ? (
                  <span style={{ marginRight: "24px" }}>
                    {this.state.sectionNumber}
                  </span>
                ) : (
                  ""
                )}
                {this.state.sectionName}
              </span>
              <h2
                ref={ref => {
                  this.title = ref;
                }}
                className="pictureTextSliderComponent__cont_content_title"
              >
                {this.state.title}
              </h2>
              <h3
                ref={ref => {
                  this.subtitle = ref;
                }}
                className="pictureTextSliderComponent__cont_content_subtitle"
              >
                {this.state.subtitle}
              </h3>
              <div
                ref={ref => {
                  this.slider = ref;
                }}
                className="pictureTextSliderComponent__cont_content_sliderCont"
              >
                <div
                  style={{
                    flexDirection:
                      isDesktop && this.state.orientation == "Left"
                        ? "row"
                        : "row-reverse"
                  }}
                  className="pictureTextSliderComponent__cont_content_sliderCont_slide"
                >
                  <div
                    ref={ref => {
                      this.slideImg = ref;
                    }}
                    className="pictureTextSliderComponent__cont_content_sliderCont_slide_image"
                  >
                    <img
                      src={this.state.slides[this.state.index].slide_image.url}
                      alt="smartwealth image slide"
                    />
                  </div>
                  <div className="pictureTextSliderComponent__cont_content_sliderCont_slide_text">
                    {this.state.slides[this.state.index].silder_title && (
                      <h3>
                        {this.state.slides[this.state.index].silder_title.text}
                      </h3>
                    )}
                    <p
                      ref={ref => {
                        this.slideText = ref;
                      }}
                    >
                      {this.state.slides[this.state.index].slide_text.text}
                    </p>
                    {this.state.slides.length > 1 && (
                      <div className="pictureTextSliderComponent__cont_content_sliderCont_slide_text_controls">
                        <div className="pictureTextSliderComponent__cont_content_sliderCont_slide_text_controls_buttons">
                          <img
                            className="pictureTextSliderComponent__cont_content_sliderCont_slide_text_controls_buttons--prev"
                            src={prevIcon}
                            alt="smartwealth previous icon"
                            style={{ opacity: this.state.index == 0 ? 0.3 : 1 }}
                            onClick={() => {
                              this.state.index > 0 && this.slidePrevious();
                            }}
                          ></img>
                          <img
                            className="pictureTextSliderComponent__cont_content_sliderCont_slide_text_controls_buttons--next"
                            src={nextIcon}
                            alt="smartwealth next icon"
                            style={{
                              opacity:
                                this.state.index + 1 == this.state.slides.length
                                  ? 0.3
                                  : 1
                            }}
                            onClick={() => {
                              this.state.index + 1 < this.state.slides.length &&
                                this.slideNext();
                            }}
                          ></img>
                        </div>
                        <div className="pictureTextSliderComponent__cont_content_sliderCont_slide_text_controls_dotCont">
                          {this.state.slides.map((slide, index) => {
                            return (
                              <div
                                className="pictureTextSliderComponent__cont_content_sliderCont_slide_text_controls_dotCont_dot"
                                style={{
                                  background:
                                    this.state.index == index
                                      ? this.state.orientation == "Left"
                                        ? "white"
                                        : "#17181D"
                                      : ""
                                }}
                              ></div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Observer>
      </section>
    );
  }
}
PictureTextSliderComponent.contextType = ContextProviderComponent;
export default PictureTextSliderComponent;
