import React from "react";
import $ from "jquery";
import "./crisisChartComponent.scss";
import Observer from "react-intersection-observer";
import lottie from "lottie-web";
import { TweenMax as TM, Power2, Power3 } from "gsap/all";
import { ContextProviderComponent } from "../../contextProviderComponent";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import {
  isMobileOnly,
  isMobile,
  isTablet,
  isDesktop
} from "react-device-detect";

import animationChart1 from "./chart_1.json";
import animationChart2DE from "./chart_1_detail_1_de.json";
import animationChart2EN from "./chart_1_detail_1_en.json";
import animationChart3DE from "./chart_1_detail_2_de.json";
import animationChart3EN from "./chart_1_detail_2_en.json";

import animationChart4 from "./chart_2.json";
import animationChart5DE from "./chart_2_detail_1_de.json";
import animationChart5EN from "./chart_2_detail_1_en.json";
import animationChart6DE from "./chart_2_detail_2_de.json";
import animationChart6EN from "./chart_2_detail_2_en.json";
import animationChart7DE from "./chart_2_detail_3_de.json";
import animationChart7EN from "./chart_2_detail_3_en.json";

import animationChart8 from "./chart_3.json";
import animationChart9 from "./chart_3_detail_1_en.json";
import animationChart10 from "./chart_3_detail_1_en.json";
import animationChart11 from "./chart_3_detail_1_en.json";

let animObj1 = null;
let animObj2 = null;
let animObj3 = null;

let animObj4 = null;
let animObj5 = null;
let animObj6 = null;
let animObj7 = null;

let animObj8 = null;
let animObj9 = null;
let animObj10 = null;
let animObj11 = null;

class CrisisChartComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionNumber: this.props.slice.primary.section_number.text || "",
      sectionName: this.props.slice.primary.section_name.text || "",
      tabs: this.props.slice.items || [],
      playedOnce: false
    };
  }

  componentDidMount() {
    $(".tooltip").on("mouseenter", function(element) {
      TM.to(
        $(element.target)
          .closest(".tooltip")
          .find(".crisisChartSection__cont_lottie-detail"),
        0.2,
        {
          borderRightColor: "#17181D"
        }
      );

      TM.to(
        $(element.target)
          .closest(".tooltip")
          .find(".tooltiptext"),
        0.2,
        {
          opacity: 1,
          onStart: function() {
            $(element.target)
              .closest(".tooltip")
              .find(".tooltiptext")
              .css("display", "block");
          }
        }
      );
    });

    $(".tooltip").on("mouseleave", function(element) {
      TM.to(
        $(element.target)
          .closest(".tooltip")
          .find(".crisisChartSection__cont_lottie-detail"),
        0.2,
        {
          borderRightColor: "transparent"
        }
      );

      TM.to(
        $(element.target)
          .closest(".tooltip")
          .find(".tooltiptext"),
        0.2,
        {
          opacity: 0,
          onComplete: function() {
            $(element.target)
              .closest(".tooltip")
              .find(".tooltiptext")
              .css("display", "none");
          }
        }
      );
    });

    ///////// LOTTIES CHART 1 /////////

    animObj1 = lottie.loadAnimation({
      container: document
        .getElementById("crisisChartSection_lottie_0")
        .getElementsByClassName("crisisChartSection__cont_lottie-main")[0], // the dom element that will contain the animation
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: animationChart1 // the path to the animation json
    });

    animObj2 = lottie.loadAnimation({
      container: document
        .getElementById("crisisChartSection_lottie_0")
        .getElementsByClassName("crisisChartSection__cont_lottie-detail1")[0], // the dom element that will contain the animation
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData:
        this.context.lang == "de-ch" ? animationChart2DE : animationChart2EN // the path to the animation json
    });

    animObj3 = lottie.loadAnimation({
      container: document
        .getElementById("crisisChartSection_lottie_0")
        .getElementsByClassName("crisisChartSection__cont_lottie-detail2")[0], // the dom element that will contain the animation
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData:
        this.context.lang == "de-ch" ? animationChart3DE : animationChart3EN // the path to the animation json
    });

    ///////////////////////////////////
    ///////// LOTTIES CHART 2 /////////
    ///////////////////////////////////

    animObj4 = lottie.loadAnimation({
      container: document
        .getElementById("crisisChartSection_lottie_1")
        .getElementsByClassName("crisisChartSection__cont_lottie-main")[0], // the dom element that will contain the animation
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: animationChart4 // the path to the animation json
    });

    animObj5 = lottie.loadAnimation({
      container: document
        .getElementById("crisisChartSection_lottie_1")
        .getElementsByClassName("crisisChartSection__cont_lottie-detail1")[0], // the dom element that will contain the animation
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData:
        this.context.lang == "de-ch" ? animationChart5DE : animationChart5EN // the path to the animation json
    });

    animObj6 = lottie.loadAnimation({
      container: document
        .getElementById("crisisChartSection_lottie_1")
        .getElementsByClassName("crisisChartSection__cont_lottie-detail2")[0], // the dom element that will contain the animation
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData:
        this.context.lang == "de-ch" ? animationChart6DE : animationChart6EN // the path to the animation json
    });

    animObj7 = lottie.loadAnimation({
      container: document
        .getElementById("crisisChartSection_lottie_1")
        .getElementsByClassName("crisisChartSection__cont_lottie-detail3")[0], // the dom element that will contain the animation
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData:
        this.context.lang == "de-ch" ? animationChart7DE : animationChart7EN // the path to the animation json
    });

    ///////////////////////////////////
    ///////// LOTTIES CHART 3 /////////
    ///////////////////////////////////

    animObj8 = lottie.loadAnimation({
      container: document
        .getElementById("crisisChartSection_lottie_2")
        .getElementsByClassName("crisisChartSection__cont_lottie-main")[0], // the dom element that will contain the animation
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: animationChart8 // the path to the animation json
    });

    animObj9 = lottie.loadAnimation({
      container: document
        .getElementById("crisisChartSection_lottie_2")
        .getElementsByClassName("crisisChartSection__cont_lottie-detail1")[0], // the dom element that will contain the animation
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: animationChart9 // the path to the animation json
    });

    animObj10 = lottie.loadAnimation({
      container: document
        .getElementById("crisisChartSection_lottie_2")
        .getElementsByClassName("crisisChartSection__cont_lottie-detail2")[0], // the dom element that will contain the animation
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: animationChart10 // the path to the animation json
    });

    animObj11 = lottie.loadAnimation({
      container: document
        .getElementById("crisisChartSection_lottie_2")
        .getElementsByClassName("crisisChartSection__cont_lottie-detail3")[0], // the dom element that will contain the animation
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: animationChart11 // the path to the animation json
    });
  }

  handleContentVisibility(inView, entry) {
    if (inView && !this.state.playedOnce) {
      TM.to($(".crisisChartSection__cont_lottie"), 1, {
        opacity: 1,
        ease: Power2.easeOut,
        delay: 0.0,
        onStart: function() {
          animObj1.play();
          setTimeout(() => {
            animObj2.play();
            setTimeout(() => {
              animObj3.play();
            }, 500);
          }, 300);

          animObj4.play();
          setTimeout(() => {
            animObj5.play();
            setTimeout(() => {
              animObj6.play();
              setTimeout(() => {
                animObj7.play();
              }, 700);
            }, 500);
          }, 300);

          animObj8.play();
          setTimeout(() => {
            animObj9.play();
            setTimeout(() => {
              animObj10.play();
              setTimeout(() => {
                animObj11.play();
              }, 700);
            }, 500);
          }, 300);
        },
        onComplete: function() {}
      });

      TM.fromTo(
        $(".crisisChartSection__cont_left__title > h2"),
        1,
        {
          opacity: 0,
          transform: "translateY(30px)"
        },
        {
          opacity: 1,
          transform: "translateY(0px)",
          ease: Power2.easeInOut
        }
      );
      TM.fromTo(
        $(".crisisChartSection__cont_right"),
        1,
        {
          opacity: 0,
          transform: "translateY(30px)"
        },
        {
          opacity: 1,
          transform: "translateY(0px)",
          ease: Power2.easeInOut
        }
      );

      this.setState({ playedOnce: true });
    }
  }

  tabSelected = (index, lastIndex) => {
    if (index === lastIndex) {
      return;
    }

    $(
      ".crisisChartSection__cont_lottie, .crisisChartSection__cont_left__title > h2, .crisisChartSection__cont_right"
    ).css("opacity", 0);
    $(
      ".crisisChartSection__cont_left__title > h2, .crisisChartSection__cont_right"
    ).css("transform", "translate(0px, 30px)");

    /*if (index == 0) {
      lottieDataTab = [
        animationChart1,
        animationChart2,
        animationChart3
      ];
    } else if (index == 1) {
      lottieDataTab = [
        animationChart4,
        animationChart5,
        animationChart6
      ];
    } else if (index == 2) {
      lottieDataTab = [
        animationChart7,
        animationChart8,
        animationChart9
      ];
    }
    
    animObj1.destroy();
    animObj2.destroy();
    animObj3.destroy();

    animObj1 = lottie.loadAnimation({
      container: document.getElementById('crisisChartSection_lottie_' + index).getElementsByClassName("crisisChartSection__cont_lottie-main")[0], // the dom element that will contain the animation
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: lottieDataTab[0] // the path to the animation json
    });

    animObj2 = lottie.loadAnimation({
      container: document.getElementById('crisisChartSection_lottie_' + index).getElementsByClassName("crisisChartSection__cont_lottie-detail1")[0], // the dom element that will contain the animation
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: lottieDataTab[1] // the path to the animation json
    });

    animObj3 = lottie.loadAnimation({
      container: document.getElementById('crisisChartSection_lottie_' + index).getElementsByClassName("crisisChartSection__cont_lottie-detail2")[0], // the dom element that will contain the animation
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: lottieDataTab[2] // the path to the animation json
    });*/

    TM.to($(".crisisChartSection__cont_lottie"), 1, {
      opacity: 1,
      ease: Power2.easeOut,
      delay: 0.0,
      onStart: function() {
        /*animObj1.play();
          setTimeout(() => {
            animObj2.play();
            setTimeout(() => {
              animObj3.play();
            }, 500)
          }, 300);*/
      },
      onComplete: function() {}
    });

    TM.fromTo(
      $(".crisisChartSection__cont_left__title > h2"),
      1,
      {
        opacity: 0,
        transform: "translateY(30px)"
      },
      {
        opacity: 1,
        transform: "translateY(0px)",
        ease: Power2.easeInOut
      }
    );
    TM.fromTo(
      $(".crisisChartSection__cont_right"),
      1,
      {
        opacity: 0,
        transform: "translateY(30px)"
      },
      {
        opacity: 1,
        transform: "translateY(0px)",
        ease: Power2.easeInOut
      }
    );
  };

  render() {
    return (
      <Observer
        threshold="0.1"
        onChange={(inView, entry) =>
          this.handleContentVisibility(inView, entry)
        }
        className="wrapper"
      >
        <section id="crisisChartSection" className="crisisChartSection">
          <Tabs
            defaultIndex={0}
            onSelect={(index, lastIndex) => this.tabSelected(index, lastIndex)}
            forceRenderTabPanel={true}
          >
            <TabList>
              {this.state.tabs.map((tab, i) => {
                return <Tab key={i}>{tab.tab_title.text}</Tab>;
              })}
            </TabList>

            {this.state.tabs.map((tab, i) => {
              return (
                <TabPanel key={i}>
                  <div className="crisisChartSection__cont">
                    <div className="crisisChartSection__cont_left">
                      <div className="crisisChartSection__cont_left__pageNumber">
                        <span>{this.state.sectionNumber}</span>
                      </div>
                      <div className="crisisChartSection__cont_left__title">
                        <span>
                          {isMobileOnly ||
                          (isTablet &&
                            window.innerHeight > window.innerWidth) ? (
                            <span style={{ marginRight: "24px" }}>
                              {this.state.sectionNumber}
                            </span>
                          ) : (
                            ""
                          )}
                          {this.state.sectionName}
                        </span>
                        <h2>{tab.content_title.text}</h2>
                      </div>
                    </div>
                    <div className="crisisChartSection__cont_right">
                      <div className="crisisChartSection__cont_right__text">
                        <p>{tab.content_text.text}</p>
                      </div>
                    </div>

                    <div
                      className="crisisChartSection__cont_lottie"
                      id={`crisisChartSection_lottie_${i}`}
                    >
                      <div className="crisisChartSection__cont_lottie-charts">
                        <div className="crisisChartSection__cont_lottie-main"></div>
                        <div className="crisisChartSection__cont_lottie-details">
                          <div className="tooltip tooltip1">
                            <div className="crisisChartSection__cont_lottie-detail crisisChartSection__cont_lottie-detail1"></div>
                            {tab.detail_1_title &&
                              tab.detail_1_title.text &&
                              tab.detail_1_title.text !== "" && (
                                <div className="tooltiptext">
                                  <div className="content">
                                    <h5>
                                      {tab.detail_1_title.text != "-"
                                        ? tab.detail_1_title.text
                                        : ""}
                                    </h5>
                                    {tab.detail_1_name_1.raw.map(
                                      (paragraph, i) => {
                                        return <p key={i}>{paragraph.text}</p>;
                                      }
                                    )}
                                    <span>
                                      {tab.detail_1_performance_1.text}
                                    </span>
                                    {tab.detail_1_name_2.raw.map(
                                      (paragraph, i) => {
                                        return <p key={i}>{paragraph.text}</p>;
                                      }
                                    )}
                                    <span>
                                      {tab.detail_1_performance_2.text}
                                    </span>
                                  </div>
                                </div>
                              )}
                          </div>

                          <div className="tooltip tooltip2">
                            <div className="crisisChartSection__cont_lottie-detail crisisChartSection__cont_lottie-detail2"></div>
                            {tab.detail_2_title &&
                              tab.detail_2_title.text &&
                              tab.detail_2_title.text !== "" && (
                                <div className="tooltiptext">
                                  <div className="content">
                                    <h5>
                                      {tab.detail_2_title.text != "-"
                                        ? tab.detail_2_title.text
                                        : ""}
                                    </h5>
                                    {tab.detail_2_name_1.raw.map(
                                      (paragraph, i) => {
                                        return <p key={i}>{paragraph.text}</p>;
                                      }
                                    )}
                                    <span>
                                      {tab.detail_2_performance_1.text}
                                    </span>
                                    {tab.detail_2_name_2.raw.map(
                                      (paragraph, i) => {
                                        return <p key={i}>{paragraph.text}</p>;
                                      }
                                    )}
                                    <span>
                                      {tab.detail_2_performance_2.text}
                                    </span>
                                  </div>
                                </div>
                              )}
                          </div>

                          <div className="tooltip tooltip3">
                            <div className="crisisChartSection__cont_lottie-detail crisisChartSection__cont_lottie-detail3"></div>
                            {tab.detail_3_title &&
                              tab.detail_3_title.text &&
                              tab.detail_3_title.text !== "" && (
                                <div className="tooltiptext">
                                  <div className="content">
                                    <h5>
                                      {tab.detail_3_title.text != "-"
                                        ? tab.detail_3_title.text
                                        : ""}
                                    </h5>
                                    {tab.detail_3_name_1.raw.map(
                                      (paragraph, i) => {
                                        return <p key={i}>{paragraph.text}</p>;
                                      }
                                    )}
                                    <span>
                                      {tab.detail_3_performance_1.text}
                                    </span>
                                    {tab.detail_3_name_2.raw.map(
                                      (paragraph, i) => {
                                        return <p key={i}>{paragraph.text}</p>;
                                      }
                                    )}
                                    <span>
                                      {tab.detail_3_performance_2.text}
                                    </span>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              );
            })}
          </Tabs>
        </section>
      </Observer>
    );
  }
}
CrisisChartComponent.contextType = ContextProviderComponent;
export default CrisisChartComponent;
