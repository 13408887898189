import React from "react";
import $ from "jquery";
import "./heroComponent.scss";
import Observer from "react-intersection-observer";
import { TweenMax as TM, Power2 } from "gsap/all";
import heroImg from "../../../images/homepage/hero.jpg";
import { ContextProviderComponent } from "../../contextProviderComponent";

//import heroVideo from "../sliderComponent/fanvest-compress.mp4";

class HeroComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: this.props.slice.primary.title.text || "",
      subtitle: this.props.slice.primary.subtitle.raw || [],
      button: this.props.slice.primary.button?.text || "",
      button_link: this.props.slice.primary.button_link?.url,
      backgroundImg: this.props.slice.primary.background_image.url || heroImg,
      backgroundPosition:
        this.props.slice.primary.background_image_position?.text || null,
      width: null,
      playedOnce: false,
      pulseAnimation:
        this.props.slice.primary.pulse_animation == null
          ? false
          : this.props.slice.primary.pulse_animation
    };
  }

  componentDidMount() {
    this.setState({ width: window.innerWidth });
  }

  handleContentVisibility(inView) {
    if (inView & !this.state.playedOnce) {
      let titleText = document.getElementById("heroTextTitle");
      let descText = document.getElementById("heroTextDesc");
      let button = document.getElementById("heroTextBtn");
      let heroImage = document.getElementById("hero_img");

      TM.fromTo(
        heroImage,
        0.5,
        { opacity: 0 },
        {
          opacity: 1,
          ease: Power2.easeOut,
          delay: 0.3
        }
      );

      TM.fromTo(
        titleText,
        1,
        { transform: "translateY(50%)", opacity: 0 },
        {
          transform: "translateY(0%)",
          opacity: 1,
          ease: Power2.easeOut,
          delay: 0.8,
          onComplete: function() {
            if (this.state.pulseAnimation) {
              $(".pulse__ring_wide, .pulse__ring_narrow").fadeIn();
            }
          }.bind(this)
        }
      );

      TM.fromTo(
        descText,
        1,
        { transform: "translateY(50%)", opacity: 0 },
        {
          transform: "translateY(0%)",
          opacity: 1,
          ease: Power2.easeOut,
          delay: 0.9
        }
      );

      TM.fromTo(
        button,
        1,
        { transform: "translateY(50%)", opacity: 0 },
        {
          transform: "translateY(0%)",
          opacity: 1,
          ease: Power2.easeOut,
          delay: 1
        }
      );

      this.setState({ playedOnce: true });
    }
  }

  buttonClickHandler = () => {
    if (this.state.button_link) {
      window.open(this.state.button_link, "_blank").focus();
    } else {
      this.context.navigateClickCallback(0);
    }
  };

  render() {
    return (
      <section
        id="hero"
        className="hero"
        style={{
          backgroundImage: "url('" + this.state.backgroundImg + "')",
          backgroundPosition: this.state.backgroundPosition
        }}
      >
        <div className="hero__cont">
          <Observer
            threshold="0.1"
            onChange={inView => this.handleContentVisibility(inView)}
            className="wrapper"
          >
            <div
              id="textCont"
              className="hero__cont_text"
              style={{
                top: "unset",
                bottom:
                  this.state.subtitle.length == 0 && !this.state.button
                    ? "15vh"
                    : "unset"
              }}
            >
              {this.state.text && <h1 id="heroTextTitle">{this.state.text}</h1>}
              {this.state.subtitle.length > 0 && (
                <div id="heroTextDesc" className="hero__cont_text_description">
                  {this.state.subtitle.map((paragraph, i) => {
                    return <p key={i}>{paragraph.text}</p>;
                  })}
                </div>
              )}
              {this.state.button && (
                <div
                  id="heroTextBtn"
                  className="hero__cont_button button"
                  onClick={this.buttonClickHandler}
                >
                  <p>{this.state.button}</p>
                </div>
              )}
            </div>
          </Observer>
        </div>
        <div className="hero__overlay"></div>
        <div className="pulse__ring_wide"></div>
        <div className="pulse__ring_narrow"></div>
      </section>
    );
  }
}
HeroComponent.contextType = ContextProviderComponent;
export default HeroComponent;
